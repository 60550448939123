<footer class="footer">
    <!-- <h6 class="lightText">This site is protected by hCaptcha and its <br>
        <a class="lightText" href="https://hcaptcha.com/privacy">Privacy Policy</a> and
        <a class="lightText" href="https://hcaptcha.com/terms">Terms of Service</a> apply.
    </h6> -->

    <hr>
    <h6 class="lightText">
        &copy; All Rights Reserved
    </h6>
    <h6 class="lightText">
        Created with ❤️ of Angular & Firebase by<a class="lightText" href="https://pratyush.dev" target="_blank"
            rel="noreferrer">
            Pratyush
        </a>
    </h6>
    <hr>
</footer>


