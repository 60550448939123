<p>
  <mat-toolbar>
    <span class="spacer"></span>

    <!-- https://codepen.io/lbebber/details/ypgql/ -->
    <div class="glitch sty" data-text="KnowYourCaller">KnowYourCaller</div> 

    <span class="spacer"></span>
  </mat-toolbar>

  <mat-toolbar class="minFontToolbar">
    <span class="spacer"></span>

    <mat-card
      color="accent"
      layout-align="center center"
      layout="column"
      class="card"
    >
      <form class="search-form">
        <span matPrefix style="font-size: medium">+91 &nbsp;</span>
        <input
          type="tel"
          minlength="9"
          maxlength="10"
          id="user-input"
          placeholder="Caller Number"
          class="number-input"
        />

        <button mat-button style="line-height: 0px" [routerLink]="'/search'">
          <mat-icon>search</mat-icon>
        </button>
      </form>
    </mat-card>

    <span class="spacer"></span>
  </mat-toolbar>

  <mat-toolbar style="height: 12px;">
  </mat-toolbar>
</p>
