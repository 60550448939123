<div class="centered">

    <h2>Whoops!</h2>
    <h2> <span style="font-family: emoji;">404 !</span> Page Not Found</h2>

    <h1>Looks like this page went on vacation.</h1>

    <img src="./assets/notfound.gif" class="img">

    <h6>
        <a href="./">Go to Homepage</a>
    </h6>

</div>

<app-footer></app-footer>