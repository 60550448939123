import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-sources',
  templateUrl: './open-sources.component.html',
  styleUrls: ['./open-sources.component.sass']
})
export class OpenSourcesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
