import { Component } from '@angular/core';

@Component({
  selector: 'app-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.sass']
})
export class MainNavBarComponent {
  searchNumber = ''
}
